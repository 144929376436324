import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const LicitacionesDetalle = () => {
  return (
    <div className="col-lg-8">
      <div className="service_details_items">
        <div className="service_details_para"></div>
        <h3>Licitaciones</h3>
        <p>Consulta en este espacio las licitaciones públicas vigentes</p>
        <Card>
          <Card.Body>
            <Card.Title>LP/MLC/RP-FIV2024/0003</Card.Title>
            <Card.Text>
              <p>
                Servicio de suministro de combustible (Gasolina y Diesel), de la
                Administración Pública Municipal de Lázaro Cárdenas, Michoacán.
              </p>
              <p>Periodo de Vigencia: 11/09/2024 al 17/09/2024</p>
              <p>Junta de Aclraciones: 19/09/2024 11:00 AM</p>
              <p>
                Presentación y Apertura de Propuestas Técnicas y Económicas:
                19/09/2024 11:00 AM
              </p>
              <p>Fallo de Adjudicación: 26/09/2024 12:00 AM</p>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Link
            role="button"
              to={{
                pathname:
                  "https://lazarocardenas.blob.core.windows.net/publico/licitaciones/CONVOCATORIA GASOLINA Y DIESEL 2024 SEP.pdf",
              }}
              target="_blank"
            >
              Convocatoria
            </Link>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};
export default LicitacionesDetalle;

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
// Import Page Layout
import Navbar from "./component/Common/Navbar";
import Footer from "./component/Common/Footer";
import CopyRight from "./component/Common/CopyRight";
// Import All Components
import Home_One from "./page/index";
import Contraloria from "./page/Contraloria";

// Import ScrollToTop Components
import ScrollToTop from "./component/ScrollToTop";
import Licitaciones from "./page/Compras/Licitaciones";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Router>
          <ScrollToTop>
            <Navbar />
            <Switch>
              <Route path="/" exact component={Home_One} />
              <Route path="/contraloria" exact component={Contraloria} />
              <Route path="/compras/licitaciones" exact component={Licitaciones} />
              <Route exact component={Error} />
            </Switch>
            <Footer />
            <CopyRight />
          </ScrollToTop>
        </Router>
      </BrowserRouter>
    </>
  );
};

export default App;

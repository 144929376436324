export const MenuData = [
  {
    name: "Inicio",
    href: "/",
    has_children: false,
  },
  {
    name: "Ayuntamiento",
    href: "#!",
    has_children: true,
    children: [
      {
        name: "Programa de Desarrollo Municipal",
        href: "/",
        has_children: false,
      },
      {
        name: "Informes de Gobierno",
        href: "/",
        has_children: false,
      },
      {
        name: "PMDULZC Vision 2040",
        href: "/",
        has_children: false,
      },
    ],
  },
  {
    name: "Dependencias",
    href: "#!",
    has_children: true,
    children: [
      {
        name: "Compras",
        href: "#!",
        has_children: true,
        children: [
          {
            name: "Licitaciones",
            href: "/compras/licitaciones",
            has_children: false,
          },
          {
            name: "Programa Anual de Aquisiciones",
            href: "/",
            has_children: false,
          },
        ],
      },
      {
        name: "Contraloria",
        href: "/contraloria",
        has_children: false,
      },
      {
        name: "Mejora Regulatoria",
        href: "#!",
        has_children: true,
        children: [
          {
            name: "Registro Municipal de Regulaciones",
            href: "http://mail.lazaro-cardenas.gob.mx/index.php/mejora-regulatoria/marco-juridico.html",
            target: "_new",
            pathname: true,
            has_children: false,
          },
          {
            name: "Mecanismo de Protesta Ciudadana",
            href: "/contraloria",
            has_children: false,
          },
          {
            name: "Actas de Cabildo",
            href: "http://mail.lazaro-cardenas.gob.mx/index.php/mejora-regulatoria/resolucion-de-actas-de-cabildo.html",
            target: "_new",
            pathname: true,
            has_children: false,
          },

          {
            name: "Registro Municipal de Inspectos y Verificadores",
            href: "http://inspectores.lazaro-cardenas.gob.mx/",
            target: "_new",
            pathname: true,
            has_children: false,
          },
          {
            name: "RMTyS",
            href: "http://rmtys.lazaro-cardenas.gob.mx/",
            target: "_new",
            pathname: true,
            has_children: false,
          }, {
            name: "Protesta Ciudadana", 
            href: "https://mejora.lazaro-cardenas.gob.mx/protesta-ciudadana",
            target: "_blank",
            pathname: true,
            has_children: false,
          }
        ],
      },
      {
        name: "Tesorería Municipal",
        href: "http://tesoreria.lazaro-cardenas.gob.mx/",
        target: "_new",
        pathname: true,
        has_children: false,
      },
      {
        name: "Turismo",
        href: "/",
        has_children: false,
      },
      {
        name: "Unidad de Transparencia",
        href: "https://transparencia.lazaro-cardenas.gob.mx/",
        target: "_new",
        pathname: true,
        has_children: false,
      },
    ],
  },

  {
    name: "Transparencia",
    href: "https://consultapublicamx.plataformadetransparencia.org.mx/vut-web/?idSujetoObigadoParametro=3469&idEntidadParametro=16&idSectorParametro=26",
    target: "_new",
    pathname: true,
    has_children: false,
  },
];

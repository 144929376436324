import img from "../../../assets/img/temporal/logovertical.png";
export const ConvocatoriaData = [
  {
    titulo: "Convocatorias",
    fecha: "27-09-2024",
    resumen:
      "Para la designación de comisiaria o comisario del organismo operador de agua potable (CAPALAC)",
    url: "https://lazarocardenas.blob.core.windows.net/publico/CONVOCATORIA_DESIGNACION_COMISARIA_O_COMISARIO_DEL_CAPALAC.pdf",
    imagen: img,
  },
  {
    titulo: "Convocatoria",
    fecha: "27-09-2024",
    resumen:
      "Para la designación de contralor o contralora municipal de Lázaro Cárdenas, Michoacán",
    url: "https://lazarocardenas.blob.core.windows.net/publico/CONVOCATORIA_DESIGNACIÓN_CONTRALOR_O_CONTRALORA_MUNICIPAL.pdf",
    imagen: img,
  },
  {
    titulo: "Convocatoria",
    fecha: "27-09-2024",
    resumen: " Para ocupar el cargo de juez cívico",
    url: "https://lazarocardenas.blob.core.windows.net/publico/convocatorias/convocatoriaJuezCivico2024.pdf",
    imagen: img,
  },
];

import CommonBanner from "../../component/Common/Banner";
import LicitacionesDetalle from "../../component/Common/Compras/Licitaciones/LicitacionesDetalle";

const Licitaciones = () => {
  return(<div>
    <CommonBanner heading="Licitaciones" page="Compras | Licitaciones" />
    <section id="service_details_area">
      <div className="container">
        <div className="row">
          <LicitacionesDetalle />
        </div>
        
      </div>
    </section>
  </div>);
};

export default Licitaciones;
